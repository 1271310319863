<template>
  <div class="manage-assigned-project-units">
    <div class="container fluid">
      <div class="card p-2">
        <h3 class="mb-3">Assigned Units</h3>

        <!-- ========================== Filter =========================== -->
        <filter-panel
          v-model="isFilterActive"
          bordered
          class="filter mb-4"
          @clear="clearFilter"
          @filter="filterData"
        >
          <div class="row p-2">
            <fd-input
              v-model="filter['street[partial]']"
              class="col-12 sm-col-6 px-1 mb-2"
              label="Street"
              name="filterUnitStreet"
              type="text"
              @keyup.enter.native="filterData"
            >
            </fd-input>
          </div>
        </filter-panel>
        <div class="row px-2 mb-2 justify-content-end">
          <button
            :class="{ active: isFilterActive }"
            class="btn toggle ml-1"
            @click="filterToggle"
          >
            <i class="fas fa-filter"></i>
          </button>
        </div>

        <!-- ========================== Contents =========================== -->
        <no-data
          v-if="units.length < 1"
          message="Oops, there is no unit available for now."
        ></no-data>

        <div v-else>
          <!-- unit cards -->
          <div class="row justify-content-start">
            <div
              v-for="unit in units"
              :key="unit.id"
              class="col-12 sm-col-6 lg-col-4 mb-4"
            >
              <unit-card
                class="m-1"
                :unit="unit"
                :canEdit="false"
                :canDelete="false"
                showProject
                showUnitType
                showFloorPlan
              >
              </unit-card>
            </div>
          </div>
        </div>

        <!-- pagination -->
        <fd-page
          v-show="units.length > 0"
          class="text-center pt-2"
          v-model="paginationParams.page"
          :total-row="unitPagination.total"
          :perPage="paginationParams.perPage"
          @page-change="pageChange"
        ></fd-page>
      </div>
    </div>
  </div>
</template>

<script>
import ManagerRoles from "@/modules/Project/classes/ManagerRoles";
import ProjectUnitAPI from "@/modules/Project/api/projectUnit";
const projectUnitAPI = new ProjectUnitAPI(ManagerRoles.AGENCY);

export default {
  components: {
    UnitCard: () => import("@/modules/Project/components/Unit/UnitCard")
  },
  mixins: [],
  props: {},
  data: function () {
    return {
      units: [],
      unitPagination: {},

      reservationFormModal: {
        unitId: "",
        isShown: false
      },

      // Filter Related
      isFilterActive: false,
      filter: {
        "street[partial]": ""
      },
      // Pagination
      paginationParams: {
        page: 1,
        perPage: 12
      }
    };
  },
  computed: {},
  watch: {},
  created: function () {},
  beforeDestroy: function () {},
  mounted: function () {
    this.init();
  },
  methods: {
    async init() {
      try {
        this.$store.commit("setIsLoading", true);
        await this.getUnits();
        this.$store.commit("setIsLoading", false);
      } catch (error) {
        this.$store.commit("setIsLoading", false);
      }
    },
    // ===================== Filter related methods =====================
    filterToggle() {
      this.isFilterActive = !this.isFilterActive;
    },
    async pageChange(info) {
      this.$store.commit("setIsLoading", true);
      this.paginationParams.page = info.pageNumber;
      await this.getUnits();
      this.$store.commit("setIsLoading", false);
    },
    async filterData() {
      this.$store.commit("setIsLoading", true);
      this.paginationParams.page = 1;
      await this.getUnits();
      this.$store.commit("setIsLoading", false);
    },
    async clearFilter() {
      if (this.$isFilterEmpty(this.filter)) {
        return;
      } else {
        this.$store.commit("setIsLoading", true);
        this.paginationParams.page = 1;
        this.filter = this._.mapValues(this.filter, () => null);
        await this.getUnits();
        this.$store.commit("setIsLoading", false);
      }
    },
    // ============================== API RELATED ==============================
    async getUnits() {
      try {
        let filteredParam = this.$cleanQueryParam(this.filter);

        let data = await projectUnitAPI.getAssignedUnits({
          queries: {
            ...filteredParam
          },
          page: this.paginationParams.page,
          perPage: this.paginationParams.perPage
        });
        this.units = this._.cloneDeep(data.data);
        this.unitPagination = this._.cloneDeep(data.meta.pagination);
      } catch (error) {
        this.$notify({
          group: "alert",
          type: "error",
          title: "Error",
          text: "An unexpected error occured. Please try again later."
        });
        this.$reportError(error, "Get Assigned Units");
        throw error;
      }
    }
  }
};
</script>

<style lang="scss">
.manage-assigned-project-units {
}
</style>
